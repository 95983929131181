import React from "react";
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";
import BackofficeNavbar from "../components/layout/BackofficeNavbar";
import BackofficeSidebar from "../components/layout/BackofficeSidebar";
import BackofficePageContainer from "../components/layout/BackofficePageContainer";
import Managers from "./Managers";

import Dashboard from "./panel/Dashboard";
import Events from "./panel/events/Events";
import EventAdd from "./panel/events/EventAdd";
import EventDetail from "./panel/events/detail/EventDetail";
import RideAdd from "./panel/rides/RideAdd";
import RideDetail from "./panel/rides/detail/RideDetail";
import Settings from "./panel/settings/Settings";

function BackofficeRouter({ match }) {
    return (
        <Managers>
            <BackofficeNavbar/>
            <BackofficeSidebar/>

            <BackofficePageContainer>
                <Switch>
                    <Route path={ match.url + "/dashboard" } component={ Dashboard }/>
                    <Route path={ match.url + "/events/add" } component={ EventAdd }/>
                    <Route path={ match.url + "/events/:eventId/rides/add" } exact component={ RideAdd }/>
                    <Route path={ match.url + "/events/:eventId/rides/:rideId" } component={ RideDetail }/>
                    <Route path={ match.url + "/events/:eventId" } component={ EventDetail }/>
                    <Route path={ match.url + "/events" } component={ Events }/>
                    <Route path={ match.url + "/settings" } component={ Settings }/>
                    <Route path={ match.url + "/" }>
                        <Redirect
                            to={{
                                pathname: match.url + "/dashboard",
                                state: { from: window.location.href }
                            }}
                        />
                    </Route>
                </Switch>
            </BackofficePageContainer>
        </Managers>
    );
}

export default withRouter(BackofficeRouter);
