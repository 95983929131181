import React from "react";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";

import {
    NavigationManager
} from "../context/NavigationContext";
import {
    MapboxStyleManager
} from "../context/MapboxStyleContext";

function Managers({ children }) {
    return (
        <SidebarManager>
            <NavigationManager>
                <MapboxStyleManager>
                    { children }
                </MapboxStyleManager>
            </NavigationManager>
        </SidebarManager>
    );
}

export default React.memo(Managers);
