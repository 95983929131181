import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Navbar
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import NavigationContext from "../../context/NavigationContext";
import OrganisationsDropdown from "./OrganisationsDropdown";

function BackofficeNavbar() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    const navigationContext = useContext(NavigationContext);

    const user = authenticatedUserContext.user;

    return (
        <Navbar
            leftChildren={
                <React.Fragment>
                    { navigationContext.backLink && (
                        <li className="nav-item">
                            <Link to={ navigationContext.backLink } className="nav-link" style={{ paddingLeft: 0 }}>
                                <i className="fas fa-chevron-circle-left mr-2"/>
                                { navigationContext.backTitle ? navigationContext.backTitle : "Terug" }
                            </Link>
                        </li>
                    )}
                </React.Fragment>
            }
        >
            <OrganisationsDropdown/>
            <NavDropdown title={ user && user.name } id="user">
                <NavDropdown.Item onClick={ authenticatedUserContext.logoutFunction }>
                    Uitloggen
                </NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    );
}

export default React.memo(BackofficeNavbar);
