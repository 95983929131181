import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";

import OrganisationsContext from "../../context/OrganisationsContext";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function OrganisationsDropdown() {
    const {
        currentOrganisation,
        organisations,
    } = useContext(OrganisationsContext);
    const {
        user,
    } = useContext(AuthenticatedUserContext);

    if(organisations?.length <= 0) {
        return null;
    }

    return (
        <NavDropdown
            title={currentOrganisation?.name ?? "Admin"}
            id="organisation"
            align="right"
            className="mr-3"
        >
            {organisations?.map((organisation) => (
                <Link
                    key={organisation.id}
                    to={"/organisation/" + organisation.codeName + "/dashboard"}
                    className={"dropdown-item" + (organisation.id === currentOrganisation?.id ? " active" : "")}
                >
                    {organisation.name}
                </Link>
            ))}
            <NavDropdown.Divider/>
            <Link to={"/organisations"} className="dropdown-item">
                Alle organisaties
            </Link>
            { user?.globalAdmin && (
                <React.Fragment>
                    <NavDropdown.Divider/>
                    <Link to="/admin" className="dropdown-item">
                        Admin
                    </Link>
                </React.Fragment>
            )}
        </NavDropdown>
    );
}

export default React.memo(OrganisationsDropdown);
